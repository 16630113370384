<template>
  <b-card>
    <b-form @submit.prevent>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Название:"
            label-for="name"
          >
            <b-form-input
              id="fio"
              v-model="brandsData.name"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        variant="primary"
        @click="formSubmit"
      >
        {{ brandsData.id ? 'Обновить' : 'Создать' }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'

export default {
  name: 'UsersForm',
  components: {
    BButton,
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
  },
  props: ['brandsData'],
  methods: {
    formSubmit() {
      this.$emit('formSubmit', true)
    },
  },
}
</script>
